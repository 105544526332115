import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import classnames from 'classnames';
import './Slider.scss';

import { SliderButton } from '@components';

const Slider = ({ className, data, heroPicker }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 431px)' });
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const slideChangeHandler = (swiper) => {
    if (heroPicker) {
      heroPicker(swiper.activeIndex);
    }
  };

  const paramsDesktop = {
    modules: [Navigation],
    spaceBetween: 50,
    slidesPerView: 1,
    centeredSlides: true,
    onSlideChange: slideChangeHandler,
  };
  const paramsMobile = {
    modules: [Navigation, Autoplay, Pagination],
    spaceBetween: 50,
    slidesPerView: 1,
    centeredSlides: true,
    onSlideChange: slideChangeHandler,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.Slider__swiper-bullets',
      type: 'bullets',
      bulletClass: 'Slider__swiper-bullet',
      bulletActiveClass: 'Slider__swiper-bullet--active',
    },
  };
  let params = isMobile ? paramsMobile : paramsDesktop;
  return (
    <Swiper
      className={classnames('Slider', className)}
      {...params}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={(swiper) => {
        swiper.params.navigation.prevEl = navigationPrevRef.current;
        swiper.params.navigation.nextEl = navigationNextRef.current;
      }}
    >
      <SliderButton direction='prev' ref={navigationPrevRef} />
      <SliderButton direction='next' ref={navigationNextRef} />

      {data.map((item) => {
        return (
          <SwiperSlide className='Slider__slide' key={item.id}>
            <img className='Slider__slide-image' src={item.image} />
            <p className='Slider__slide-name'>{item.name}</p>
            <p
              className='Slider__slide-description'
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </SwiperSlide>
        );
      })}
      <div className='Slider__swiper-bullets'></div>
    </Swiper>
  );
};

export default Slider;
