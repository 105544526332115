import classnames from 'classnames';
import './Arrow.scss';
import { arrowLeftIcon } from '@/assets/icons';

const Arrow = () => {
  return (
    <div className={classnames('Arrow')}>
      <img className='Arrow__icon' src={arrowLeftIcon} alt='icon arrow' />
      <svg
        className='Arrow__line'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 77 57'
        fill='none'
      >
        <path
          d='M73.516 1.245C50.977.132 6.257 2.246 7.686 19.608c1.786 21.702 50.52 41.068 65.83 11.353C85.763 7.188 49.93 6.46 27.078 10.928c-11.561 2.26-18.32 7.212-25.26 20.033C-5.122 43.78 33.798 52.329 54.124 55'
          stroke='#fff'
          strokeWidth='2'
        />
      </svg>
    </div>
  );
};

export default Arrow;
