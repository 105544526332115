import classnames from 'classnames';
import './Gratitude.scss';
import { Logo, Button, Person } from '@/components';
import { smallHouseIcon, bigHouseIcon } from '@/assets/icons';

const Gratitude = ({ className }) => {
  return (
    <div className={classnames('Gratitude', className)}>
      <Logo className='Gratitude__logo' />
      <Button className='Gratitude__buttom' link='/game'>
        Играть
      </Button>
      <div className='Gratitude__content'>
        <div className='Gratitude__top'>
          <div className='Gratitude__icons'>
            <img
              className='Gratitude__icon'
              src={smallHouseIcon}
              alt='icon house'
            />
            <Person className='Gratitude__person' />
            <img
              className='Gratitude__icon'
              src={bigHouseIcon}
              alt='icon house'
            />
          </div>
        </div>

        <div className='Gratitude__footer'>
          <h3 className='Gratitude__title'>Благодарим за пожертвование!</h3>
        </div>
      </div>
    </div>
  );
};

export default Gratitude;
