import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import './Help.scss';
import { Logo, Button } from '@/components';
import { memo, useEffect } from 'react';

const createScript = (url, callback = () => {}) => {
  const script = document.createElement('script');

  script.src = url;
  script.async = true;

  script.addEventListener('load', callback);

  return script;
};

const Help = memo(({ className }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const donationScript = createScript(
      'https://donate.podari-zhizn.ru/donate-api/v3.js',
      () => {
        const formMain = new window.GLDonate({
          el: '#donate-form',
          form: 'small',
          pageSource: 'details',
          sum: 700,

          currencySettings: () => ({
            RUB: {
              scale: [700, 1200],
              symbol: 'руб',
            },
            force: true,
          }),
          onSuccess: () => {
            navigate('gratitude');
          },
        });
        formMain.mount();
      }
    );
    const paymentScript = createScript(
      'https://widget.cloudpayments.ru/bundles/cloudpayments'
    );

    document.body.appendChild(donationScript);
    document.body.appendChild(paymentScript);

    return () => {
      document.body.removeChild(donationScript);
      document.body.removeChild(paymentScript);
    };
  }, []);

  const handleSubmit = () => {
    const button = document.querySelector('button.SmallForm__button');
    if (button) {
      button.click();
    }
  };

  const isMobile = useMediaQuery({ query: '(max-width: 431px)' });
  return (
    <div className={classnames('Help', className)}>
      <link
        rel='stylesheet'
        href='https://donate.podari-zhizn.ru/donate-api/v3.css'
      />
      <Logo className='Help__logo' />

      <div className='Help__left'>
        {!isMobile && (
          <h1 className='Help__title'>Помогайте. И всё сложится</h1>
        )}
        <Button variant='button' onClick={handleSubmit}>
          Помочь
        </Button>
      </div>

      <div className='Help__content'>
        <div className='Help__content-top'>
          <p className='Help__text'>
            Помогите детям вылечиться, совершив пожертвование
          </p>
          <h2 className='Help__title'>Помочь</h2>
        </div>
        <div className='Help__content-bottom'>
          <div id='donate-form' />
        </div>
      </div>
    </div>
  );
});

export default Help;
