import React, { useState } from 'react';
import classnames from 'classnames';
import './Quadrant.scss';

import { PageLink } from '@components';

const Quadrant = ({ className }) => {
  const [activeQuadrant, setActiveQuadrant] = useState(null);
  const [animatedQuadrant, setAnimatedQuadrant] = useState(null);

  const handleMouseEnter = (active, animated) => {
    setActiveQuadrant(active);
    setAnimatedQuadrant(animated);
  };

  const handleMouseLeave = () => {
    setActiveQuadrant(null);
    setAnimatedQuadrant(null);
  };

  return (
    <div className={classnames('Quadrant', className)}>
      <div className='Quadrant__top'>
        <div
          className={classnames('Quadrant__top-left', 'Quadrant__item', {
            active: activeQuadrant === 'Quadrant__top-left',
            animated: animatedQuadrant === 'Quadrant__top-left',
          })}
          onMouseEnter={() =>
            handleMouseEnter('Quadrant__top-left', 'Quadrant__bottom-left ')
          }
          onMouseLeave={handleMouseLeave}
        >
          <div className='box'>
            <div className='box__top'>
              <div className='box__top-col1'></div>
              <div className='box__top-col2'></div>
              <div className='box__top-col3'></div>
              <div className='box__top-col4'></div>
              <div className='box__top-col5'></div>
            </div>
            <div className='box__bottom'>
              <div className='box__bottom-col1'></div>
              <div className='box__bottom-col2'></div>
              <div className='box__bottom-col3'></div>
              <div className='box__bottom-col4'></div>
              <div className='box__bottom-col5'></div>
            </div>
          </div>
          <div className='box-content'>
            <PageLink link='game' text='Играть' />
          </div>
        </div>
        <div
          className={classnames('Quadrant__top-right', 'Quadrant__item', {
            active: activeQuadrant === 'Quadrant__top-right',
            animated: animatedQuadrant === 'Quadrant__top-right',
          })}
          onMouseEnter={() =>
            handleMouseEnter('Quadrant__top-right', 'Quadrant__bottom-right')
          }
          onMouseLeave={handleMouseLeave}
        >
          <div className='box'>
            <div className='box__top'>
              <div className='box__top-top'>
                <div className='box__top-top__col1'></div>
                <div className='box__top-top__col2'></div>
                <div className='box__top-top__col3'></div>
              </div>
              <div className='box__top-bootom'>
                <div className='box__top-bootom__col1'></div>
                <div className='box__top-bootom__col2'></div>
                <div className='box__top-bootom__col3'></div>
              </div>
            </div>
            <div className='box__bottom'>
              <div className='box__bottom-top'>
                <div className='box__bottom-top__col1'></div>
                <div className='box__bottom-top__col2'></div>
                <div className='box__bottom-top__col3'></div>
              </div>
              <div className='box__bottom-bottom'>
                <div className='box__bottom-bottom__col1'></div>
                <div className='box__bottom-bottom__col2'></div>
                <div className='box__bottom-bottom__col3'></div>
              </div>
            </div>
          </div>
          <div className='box-content'>
            <PageLink link='wards' text='О подопечных' />
          </div>
        </div>
      </div>
      <div className='Quadrant__bottom'>
        <div
          className={classnames('Quadrant__bottom-left ', 'Quadrant__item', {
            active: activeQuadrant === 'Quadrant__bottom-left ',
            animated: animatedQuadrant === 'Quadrant__bottom-left ',
          })}
          onMouseEnter={() =>
            handleMouseEnter('Quadrant__bottom-left ', 'Quadrant__top-left')
          }
          onMouseLeave={handleMouseLeave}
        >
          <div className='box'>
            <div className='box__top'>
              <div className='box__top-col1'></div>
              <div className='box__top-col2'></div>
              <div className='box__top-col3'></div>
            </div>
            <div className='box__bottom'>
              <div className='box__bottom-top'>
                <div className='box__bottom-top__col1'></div>
                <div className='box__bottom-top__col2'></div>
                <div className='box__bottom-top__col3'></div>
              </div>
              <div className='box__bottom-bottom'>
                <div className='box__bottom-bottom__col1'></div>
                <div className='box__bottom-bottom__col2'></div>
                <div className='box__bottom-bottom__col3'></div>
              </div>
            </div>
          </div>
          <div className='box-content'>
            <PageLink link='about' text='О фонде' />
          </div>
        </div>
        <div
          className={classnames('Quadrant__bottom-right', 'Quadrant__item', {
            active: activeQuadrant === 'Quadrant__bottom-right',
            animated: animatedQuadrant === 'Quadrant__bottom-right',
          })}
          onMouseEnter={() =>
            handleMouseEnter('Quadrant__bottom-right', 'Quadrant__top-right')
          }
          onMouseLeave={handleMouseLeave}
        >
          <div className='box'>
            <div className='box__left'>
              <div className='box__left-row1'></div>
              <div className='box__left-row2'></div>
              <div className='box__left-row3'></div>
            </div>
            <div className='box__right'>
              <div className='box__right-row1'></div>
              <div className='box__right-row2'></div>
              <div className='box__right-row3'></div>
              <div className='box__right-row4'></div>
              <div className='box__right-row5'></div>
            </div>
          </div>
          <div className='box-content'>
            <PageLink link='help' text='Помочь' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quadrant;
