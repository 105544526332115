import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './Button.scss';

const Button = ({ className, link, children, variant = 'link', onClick }) => {
  return (
    <>
      {variant === 'link' && (
        <Link to={link} className={classnames('Button', className)}>
          {children}
        </Link>
      )}
      {variant === 'button' && (
        <button className={classnames('Button', className)} onClick={onClick}>
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
