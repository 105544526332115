import React, { forwardRef } from 'react';
import classnames from 'classnames';
import './SliderButton.scss';

import { arrowLeftIcon, arrowRightIcon } from '@/assets/icons';

const SliderButton = forwardRef(({ direction }, ref) => {
  return (
    <>
      {direction === 'next' && (
        <button
          ref={ref}
          className={classnames('SliderButton', 'SliderButton--next')}
        >
          <img src={arrowRightIcon} alt='icon arrow' />
          <svg
            className='SliderButton__line'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 110 82'
            fill='none'
          >
            <path
              stroke='#fff'
              strokeWidth='2'
              d='M105.422 1.858C72.967.23 8.571 3.323 10.628 28.723c2.572 31.75 72.748 60.081 94.794 16.608 17.637-34.778-33.961-35.842-66.87-29.307-16.647 3.306-26.38 10.55-36.374 29.307C-7.816 64.088 48.228 76.592 77.5 80.5'
            />
          </svg>
        </button>
      )}
      {direction === 'prev' && (
        <button
          ref={ref}
          className={classnames('SliderButton', 'SliderButton--prev')}
        >
          <img src={arrowLeftIcon} alt='icon arrow' />
          <svg
            className='SliderButton__line'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 110 82'
            fill='none'
          >
            <path
              stroke='#fff'
              strokeWidth='2'
              d='M4.578 1.858C37.033.23 101.429 3.323 99.372 28.723 96.8 60.473 26.623 88.804 4.578 45.331-13.06 10.553 38.539 9.49 71.448 16.024c16.647 3.306 26.38 10.55 36.374 29.307 9.994 18.757-46.05 31.261-75.321 35.169'
            />
          </svg>
        </button>
      )}
    </>
  );
});

export default SliderButton;
