import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './PageLink.scss';
const PageLink = ({ link, text, className }) => {
  return (
    <Link to={link} className={classnames('PageLink', className)}>
      <p className='PageLink__text'>{text}</p>
      <svg
        className='PageLink__line'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 445 255'
        fill='none'
      >
        <path
          stroke='#fff'
          strokeWidth='2'
          d='M428.727 3.066C296.082-2.126 32.894 7.739 41.303 88.738c10.512 101.249 297.322 191.599 387.424 52.963 72.08-110.908-138.8-114.301-273.296-93.46C87.39 58.782 47.613 81.885 6.768 141.7c-40.845 59.815 188.206 99.692 307.836 112.153'
        />
      </svg>
    </Link>
  );
};

export default PageLink;
