import { useContext } from 'react';
import classnames from 'classnames';
import './Game.scss';
import { Logo, Person, Button } from '@components';
import { smallHouseIcon, bigHouseIcon, figureIcon } from '@/assets/icons';
import { GameResultContext } from '@/providers/GameResultProvider';
const Game = ({ className }) => {
  const { hasPlayerWon } = useContext(GameResultContext);
  return (
    <div className={classnames('Game', className)}>
      <Logo className='Game__logo' />
      <div className='Game__center'>
        <div className='Game__icons'>
          <img className='Game__icon' src={smallHouseIcon} alt='icon house' />
          <Person className='Game__person' />
          <img className='Game__icon' src={bigHouseIcon} alt='icon house' />
        </div>
        <h1 className='Game__title'>
          Помогайте.
          <span> И всё сложится</span>
        </h1>
        <div className='Game__description'>
          {!hasPlayerWon ? (
            <>
              <p className='Game__text'>
                Выздоровление складывается из&nbsp;самых разных вещей. Это
                лекарства и&nbsp;своевременная диагностика. Это хороший доктор
                и&nbsp;заботливые родные. Это желание жить и&nbsp;помощь
                благотворителей.
              </p>
              <p className='Game__text'>
                Чтобы узнать, как подопечные фонда &laquo;Подари жизнь&raquo;
                победили болезнь, предлагаем сыграть в&nbsp;небольшую игру
                на&nbsp;основе реальных историй. Для этого найдите
                на&nbsp;картинке ключевые элементы с&nbsp;помощью подсказок
                и&nbsp;узнайте подробности выздоровления
              </p>
            </>
          ) : (
            <>
              <p className='Game__text'>С возвращением!</p>
              <p className='Game__text'>
                Возможно, вам захочется узнать истории других подопечных фонда.
                Или снова с&nbsp;кем-то из&nbsp;героев пройти путь
                к&nbsp;выздоровлению. Главное&nbsp;&mdash; помогать,
                и&nbsp;тогда все сложится!
              </p>
            </>
          )}
        </div>
        <Button className='Game__button' link='hero-picker'>
          Играть
        </Button>
      </div>
      <img className='Game__right' src={figureIcon} alt='figure icon' />
    </div>
  );
};

export default Game;
