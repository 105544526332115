import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Home, Game, Help, About, Wards } from '@/pages';
import { WardProfile, HeroPicker, PlayingField, Gratitude } from '@/components';
import { WardsLayout } from '@/layout';
import '@/styles/main.scss';

import { GameResultProvider } from '@/providers/GameResultProvider';
const Router = () => {
  return (
    <GameResultProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} path='/' />
          <Route element={<Game />} path='game' />
          <Route element={<HeroPicker />} path='game/hero-picker' />
          <Route element={<PlayingField />} path='game/:id' />
          <Route path='wards/*'>
            <Route element={<WardsLayout />}>
              <Route index element={<Wards />} />
              <Route element={<WardProfile />} path=':id' />
            </Route>
          </Route>
          <Route element={<Help />} path='help' />
          <Route element={<Gratitude />} path='help/gratitude' />
          <Route element={<About />} path='about' />
        </Routes>
      </BrowserRouter>
    </GameResultProvider>
  );
};
export default Router;
