import { useState, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import './HeroPicker.scss';
import { Button, Slider, Logo } from '@components';
import { GameResultContext } from '@/providers/GameResultProvider';

import heroList from '@/data/hero.data';

const HeroPicker = ({ className }) => {
  const { setHasPlayerWon } = useContext(GameResultContext);
  const [step, setStep] = useState('hero');
  const [selectHero, setSelectHero] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 431px)' });

  const nextStepHandler = () => {
    if (step === 'hero') {
      setStep('hints');
    } else if (step === 'hints') {
      setStep('rules');
    }
    setHasPlayerWon(false);
  };
  const heroPickerHandler = (index) => {
    setSelectHero(index);
  };

  return (
    <div className={classnames('HeroPicker', className)}>
      <Logo className='HeroPicker__logo' />
      <div
        className={classnames('HeroPicker__content', {
          'HeroPicker__content--rules': step === 'rules',
        })}
      >
        {step === 'hero' && (
          <div className='HeroPicker__hero'>
            <h2 className='HeroPicker__title'>Выберите героя</h2>
            <Slider data={heroList} heroPicker={heroPickerHandler} />
            <Button
              className={classnames(
                'HeroPicker__button',
                'HeroPicker__button--hero'
              )}
              variant='button'
              onClick={nextStepHandler}
            >
              Далее
            </Button>
          </div>
        )}
        {step === 'hints' && (
          <div className='HeroPicker__hints'>
            <h2 className='HeroPicker__title'>Подсказки</h2>
            {!isMobile && (
              <p className='HeroPicker__text'>
                Их вам предстоит найти в игре. Нажмите на кнопку «Далее» и ищите
                подсказки, чтобы история выздоровления{' '}
                {heroList[selectHero].name_game} сложилась. Если вы хотите
                вернуться к выбору персонажа, поможет кнопка «Назад»
              </p>
            )}

            <Slider
              className='HeroPicker__hints-slider'
              data={heroList[selectHero].hints}
            />
            {isMobile ? (
              <div className='HeroPicker__buttons'>
                {' '}
                <Button
                  className='HeroPicker__button'
                  variant='button'
                  onClick={() => setStep('hero')}
                >
                  Назад
                </Button>
                <Button
                  className='HeroPicker__button'
                  variant='button'
                  onClick={nextStepHandler}
                >
                  Далее
                </Button>
              </div>
            ) : (
              <div className='HeroPicker__buttons'>
                <Button
                  className='HeroPicker__button'
                  variant='button'
                  onClick={() => setStep('hero')}
                >
                  Назад
                </Button>
                <Button
                  className='HeroPicker__button'
                  link={`/game/${selectHero}`}
                >
                  Далее
                </Button>
              </div>
            )}
          </div>
        )}
        {isMobile && step === 'rules' && (
          <div className='HeroPicker__rules'>
            <p className='HeroPicker__rules-text'>
              Подсказки помогут вам собрать всю историю выздоровления{' '}
              {heroList[selectHero].name_game}. Нужные элементы станут цветными
              и расскажут, как именно они участвовали в лечении подопечных фонда
            </p>
            <div className='HeroPicker__rules-hints'>
              {heroList[selectHero].hints.map((hint) => {
                return (
                  <img
                    src={hint.image}
                    className={classnames('HeroPicker__rules-hint')}
                    key={hint.id}
                  />
                );
              })}
            </div>
            <Button className='HeroPicker__button' link={`/game/${selectHero}`}>
              Играть
            </Button>
            <p className='HeroPicker__rules-text'>
              Двигайтесь по экрану и приближайте изображение, чтобы разглядеть
              детали
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroPicker;
