import { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import './PlayingField.scss';

import {
  GameLisa,
  GameUlyana,
  GameSasha,
  Button,
  PageLink,
  Share,
} from '@components';

import heroList from '@/data/hero.data';
import { GameResultContext } from '@/providers/GameResultProvider';

const PlayingField = ({ className }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 431px)' });
  const params = useParams();
  const hero = heroList.find((hero) => hero.id === parseInt(params.id));

  const [hintsStates, setHintsStates] = useState({});

  const { hasPlayerWon, setHasPlayerWon } = useContext(GameResultContext);

  const nodeRef = useRef(null);

  useEffect(() => {
    const initialHintsStates = {};

    hero.hints.forEach((hint) => {
      initialHintsStates[hint.nameEn] = false;
    });

    setHintsStates(initialHintsStates);
  }, []);

  useEffect(() => {
    const isAllHintsTrue = Object.values(hintsStates).every((value) => value);
    if (Object.keys(hintsStates).length > 0 && isAllHintsTrue) {
      setTimeout(() => {
        setHasPlayerWon(true);
      }, 5000);
    }
  }, [hintsStates]);

  const handleClick = (blockName) => {
    setHintsStates((prevState) => ({
      ...prevState,
      [blockName]: true,
    }));
  };

  return (
    <div
      className={classnames(
        'PlayingField',
        {
          'PlayingField--noscroll': hasPlayerWon,
        },
        className
      )}
    >
      <div className='PlayingField__top'>
        <div className='PlayingField__top-wrapper'>
          {!isMobile && (
            <p className='PlayingField__top-text'>
              Подсказки из&nbsp;предыдущего шага помогут вам собрать всю историю
              выздоровления {hero.name_game}. По&nbsp;клику нужные элементы
              станут цветными и&nbsp;расскажут, как именно они участвовали
              в&nbsp;лечении подопечных фонда
            </p>
          )}

          <div className='PlayingField__hints'>
            {hero.hints.map((hint) => {
              return (
                <img
                  src={hint.image}
                  className={classnames('PlayingField__hint', {
                    'PlayingField__hint--active': hintsStates[hint.nameEn],
                  })}
                  key={hint.id}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className='PlayingField__game'>
        {hero.id === 0 && (
          <GameUlyana hintsStates={hintsStates} handleClick={handleClick} />
        )}
        {hero.id === 1 && (
          <GameSasha hintsStates={hintsStates} handleClick={handleClick} />
        )}
        {hero.id === 2 && (
          <GameLisa hintsStates={hintsStates} handleClick={handleClick} />
        )}
      </div>
      <CSSTransition
        in={hasPlayerWon}
        nodeRef={nodeRef}
        timeout={300}
        classNames='alert'
        unmountOnExit
      >
        <div className='PlayingField__victory' ref={nodeRef}>
          <p className='PlayingField__victory-title'>
            Отлично! Вы нашли все подсказки, и история сложилась!
          </p>
          <p
            className='PlayingField__victory-text'
            dangerouslySetInnerHTML={{ __html: hero.victory }}
          ></p>
          <div className='PlayingField__victory-buttons'>
            <Button link='/help'>Помочь</Button>
            {isMobile ? (
              <Button className='PlayingField__victory-button' link='/'>
                В начало
              </Button>
            ) : (
              <PageLink
                className='PlayingField__victory-PageLink'
                text='В начало'
                link='/'
              />
            )}
          </div>
          <Share />
        </div>
      </CSSTransition>
    </div>
  );
};

export default PlayingField;
