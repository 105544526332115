import { createContext, useState } from 'react';

export const GameResultContext = createContext({
  hasPlayerWon: false,
  setHasPlayerWon: () => {},
});

export const GameResultProvider = ({ children }) => {
  const [hasPlayerWon, setHasPlayerWon] = useState(false);

  return (
    <GameResultContext.Provider value={{ hasPlayerWon, setHasPlayerWon }}>
      {children}
    </GameResultContext.Provider>
  );
};
