import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './About.scss';

import { LeftPanel, Arrow, Logo } from '@/components';

const About = ({ className }) => {
  return (
    <div className={classnames('About', className)}>
      <Logo className='About__logo' />
      <LeftPanel className='About__LeftPanel' />
      <div className='About__content'>
        <div className='About__top'>
          <p className='About__suptitle'>
            Детский рак лечится. Мы работаем, чтобы любой ребёнок в России мог
            получить всё необходимое лечение.
          </p>
          <h1 className='About__title'>О фонде</h1>
        </div>
        <div className='About__bottom'>
          <p className='About__discription'>
            Мы&nbsp;&mdash; это фонд &laquo;Подари жизнь&raquo; в&nbsp;самом
            широком смысле. Благотворители, волонтеры, врачи, доноры,
            попечители, все&nbsp;те, кто делает возможной помощь больным детям.
            Мы&nbsp;хотим, чтобы в&nbsp;России любой ребенок или взрослый
            до&nbsp;25&nbsp;лет с&nbsp;онкологическим или гематологическим
            заболеванием мог получить самую эффективную и&nbsp;современную
            медицинскую помощь, а&nbsp;также немедицинскую поддержку. Вместе
            с&nbsp;вами мы&nbsp;смогли поддержать уже более
            85&nbsp;000&nbsp;детей.
          </p>
          <Link className='About__link' to='..'>
            <Arrow />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
