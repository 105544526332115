import {
  beforeUlyana,
  afterUlyana,
  beforeSasha,
  afterSasha,
  beforeLisa,
  afterLisa,
} from '@/assets/images';

const wardList = [
  {
    id: 0,
    name: 'Ульяна Клаченко',
    photo_before: beforeUlyana,
    photo_after: afterUlyana,
    description:
      'Ульяна&nbsp;&mdash; настоящий герой. Совсем малышом она заболела сложным вариантом лейкоза. Недолгие ремиссии и&nbsp;мутация опухолевых клеток, казалось, не&nbsp;оставят ей&nbsp;ни&nbsp;одного шанса. Однако она справилась! Ее&nbsp;выздоровление сложилось из&nbsp;безграничной любви мамы Юлии, постоянной поддержки старших братьев, квалифицированных врачей и&nbsp;успешной пересадки костного мозга. Девять месяцев длилось тяжелое лечение, но&nbsp;фонд помогал не&nbsp;только материально, но&nbsp;и&nbsp;скрашивал время и&nbsp;Ульяне, и&nbsp;ее&nbsp;маме. Ульяна провела много времени в&nbsp;больнице и&nbsp;там&nbsp;же научилась ходить с&nbsp;помощью ходунков, подаренных фондом. Сейчас Ульяна уже дома вместе с&nbsp;семьей.',
  },
  {
    id: 1,
    name: 'Саша Бабушкин',
    photo_before: beforeSasha,
    photo_after: afterSasha,
    description:
      'История Сашиной болезни завершилась, и&nbsp;завершилась счастливо. Конечно, чаще всего главный фактор выздоровления&nbsp;&mdash; своевременное лечение. В&nbsp;случае Саши оно стало возможным благодаря помощи фонда и&nbsp;акции &laquo;Подарок&nbsp;от: важных людей&raquo;. Но&nbsp;свою роль сыграл и&nbsp;спортивный характер Саши, и&nbsp;люди, которые его окружали и&nbsp;помогали переносить лечение и&nbsp;больничный режим. Саша оказался настоящим бойцом и&nbsp;воспринял болезнь как вызов. Болезнь не&nbsp;выдержала, а&nbsp;Саша сейчас уже учится на&nbsp;третьем курсе МГСУ и&nbsp;планирует стать инженером. Получается, что счастливый конец складывается из&nbsp;множества вещей, в&nbsp;том числе помощи. Помогайте, и&nbsp;все сложится!',
  },
  {
    id: 2,
    name: 'Лиза Акперова',
    photo_before: beforeLisa,
    photo_after: afterLisa,
    description:
      'С&nbsp;девяти лет Лиза болела лимфобластным лейкозом, который трижды возвращался в&nbsp;ее&nbsp;жизнь. Трижды Лиза проходила тяжелое лечение, трижды ее&nbsp;уносило из&nbsp;обычной жизни в&nbsp;больничную. Но&nbsp;Лиза была не&nbsp;одна&nbsp;&mdash; ей&nbsp;помогала семья, а&nbsp;участие старшего брата оказалось решающим благодаря донорству костного мозга. Не&nbsp;оставляли ее&nbsp;и&nbsp;волонтеры фонда, с&nbsp;которыми она подружилась и&nbsp;которые помогали ей&nbsp;отвлечься от&nbsp;больничной обстановки. Удачная ТКМ и&nbsp;помощь фонда поставили точку в&nbsp;истории болезни Лизы. Сейчас она заканчивает обучение и&nbsp;уже скоро станет фельдшером.',
  },
];

export default wardList;
