import classnames from 'classnames';
import './Logo.scss';
import logo from '@/assets/icons/logo.svg';

const Logo = ({ className }) => {
  return (
    <a
      className={classnames('Logo', className)}
      href='https://podari-zhizn.ru/ru'
      target='_blank'
    >
      <img className='Logo__img' src={logo} alt='logo' />
    </a>
  );
};

export default Logo;
