import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import './QuadrantMobile.scss';

import { PageLink } from '@components';

const QuadrantMobile = ({ className }) => {
  return (
    <div className={classnames('QuadrantMobile', className)}>
      <Swiper
        className='QuadrantMobile__swiper'
        modules={[Autoplay, Pagination]}
        slidesPerView={1}
        centeredSlides
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          el: '.QuadrantMobile__swiper-bullets',
          type: 'bullets',
          bulletClass: 'QuadrantMobile__swiper-bullet',
          bulletActiveClass: 'QuadrantMobile__swiper-bullet--active',
        }}
      >
        <SwiperSlide className='QuadrantMobile__swiper-slide QuadrantMobile__swiper-slide--game'>
          <div className='QuadrantMobile__link'>
            <PageLink
              className='QuadrantMobile__PageLink'
              link='game'
              text='Играть'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className='QuadrantMobile__swiper-slide QuadrantMobile__swiper-slide--wards'>
          <div className='QuadrantMobile__link'>
            <PageLink
              className='QuadrantMobile__PageLink'
              link='wards'
              text='О подопечных'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className='QuadrantMobile__swiper-slide QuadrantMobile__swiper-slide--help'>
          <div className='QuadrantMobile__link'>
            <PageLink
              className='QuadrantMobile__PageLink'
              link='help'
              text='Помочь'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className='QuadrantMobile__swiper-slide QuadrantMobile__swiper-slide--about'>
          <div className='QuadrantMobile__link'>
            <PageLink
              className='QuadrantMobile__PageLink'
              link='about'
              text='О фонде'
            />
          </div>
        </SwiperSlide>
        <div className='QuadrantMobile__swiper-bullets'></div>
      </Swiper>
    </div>
  );
};

export default QuadrantMobile;
