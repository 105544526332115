import {
  ulyanaIcon,
  sashaIcon,
  lisaIcon,
  phoneIcon,
  strollersIcon,
  balloonsIcon,
  physicianIcon,
  philanthropistIcon,
  consoleIcon,
  hockeyStickIcon,
  santaClausIcon,
  companyOfficeIcon,
  apartmentIcon,
  carIcon,
  bigBrotherIcon,
  wheelchairIcon,
  donorIcon,
  charitySalesIcon,
} from '@/assets/images';

const heroList = [
  {
    id: 0,
    name: 'Это Ульяна',
    name_game: 'Ульяны',
    image: ulyanaIcon,
    description:
      'Полтора года назад у&nbsp;двухлетней Ульяны обнаружили сложный вариант лейкоза. Ульяна прошла долгое и&nbsp;непростое лечение, но&nbsp;все сложилось хорошо. Игра расскажет, что именно помогло Ульяне справиться с&nbsp;болезнью',
    hints: [
      {
        id: 0,
        name: 'Телефон',
        nameEn: 'phone',
        image: phoneIcon,
        description:
          'Видеозвонки от&nbsp;старших братьев стали главной поддержкой Ульяны',
      },
      {
        id: 1,
        name: 'Ходунки',
        image: strollersIcon,
        nameEn: 'strollers',
        description:
          'Ходунки, подаренные фондом, помогли энергичной Ульяне поскорее встать на&nbsp;ноги',
      },
      {
        id: 2,
        name: 'Воздушные шарики',
        nameEn: 'balloons',
        image: balloonsIcon,
        description:
          'Воздушные шарики приводили Ульяну в&nbsp;восторг и&nbsp;сделали пребывание в&nbsp;палате веселее',
      },
      {
        id: 3,
        name: 'Лечащий врач',
        nameEn: 'physician',
        image: physicianIcon,
        description:
          'Лечащий врач&nbsp;&mdash; лучший друг подопечного, а&nbsp;часто&nbsp;&mdash; еще и&nbsp;его близких',
      },
      {
        id: 4,
        name: 'Благотворитель',
        nameEn: 'philanthropist',
        image: philanthropistIcon,
        description:
          'Без благотворителей никуда: даже небольшая помощь&nbsp;&mdash; чья-то надежда и&nbsp;победа',
      },
    ],
    victory:
      'Ульяне было всего 2&nbsp;года, когда у&nbsp;нее обнаружили сложный вариант лейкоза. Спасла пересадка костного мозга, и, конечно, помощь родных. Большую поддержку Ульяне оказали старшие братья, не&nbsp;дававшие сестре унывать. После долгих 9&nbsp;месяцев лечения она оказалась дома. Отвлечься от&nbsp;тяжелых мыслей Ульяне и&nbsp;ее&nbsp;маме Юлии помогали лечащий врач и&nbsp;мероприятия фонда с&nbsp;представлениями и&nbsp;полюбившимся ей&nbsp;шариками. А&nbsp;благодаря ходункам, подаренным фондом, Ульяна научилась ходить.',
  },
  {
    nameEn: 'apartment',
    id: 1,
    name: 'Это Саша',
    name_game: 'Саши',
    image: sashaIcon,
    description:
      'В&nbsp;больницу Саша попал в&nbsp;15&nbsp;лет. Сначала на&nbsp;УЗИ у&nbsp;него нашли новообразование в&nbsp;кишечнике, позднее анализы подтвердили лимфому. Болезнь поддалась лечению не&nbsp;сразу, а&nbsp;Саша в&nbsp;итоге проболел весь девятый класс. В&nbsp;игре вы&nbsp;узнаете, как Саша смог победить болезнь',
    hints: [
      {
        id: 0,
        name: 'Приставка',
        nameEn: 'console',
        image: consoleIcon,
        description:
          'Долгие больничные дни были&nbsp;бы еще длиннее, если&nbsp;бы не&nbsp;игровая приставка',
      },
      {
        id: 1,
        name: 'Хоккейная клюшка',
        nameEn: 'hockeyStick',
        image: hockeyStickIcon,
        description:
          'Хоккей сыграл большую роль в&nbsp;жизни Саши и&nbsp;помог найти друзей',
      },
      {
        id: 2,
        name: 'Дед Мороз',
        nameEn: 'santaClaus',
        image: santaClausIcon,
        description:
          'Дед Мороз преподнес ценный подарок, хотя Саша совсем не&nbsp;ожидал такого сюрприза',
      },
      {
        id: 3,
        name: 'Офис компании',
        nameEn: 'companyOffice',
        image: companyOfficeIcon,
        description:
          'Офис одной из&nbsp;компаний, где работают очень добрые люди',
      },
      {
        id: 4,
        name: 'Квартира',
        nameEn: 'apartment',
        image: apartmentIcon,
        description:
          'Тяжело находиться так долго в&nbsp;больнице, но&nbsp;выручить может амбулаторная квартира',
      },
    ],
    victory:
      'Здоровому человеку сложно представить, что можно заболеть. Да&nbsp;еще так серьезно, что потребуется госпитализация и&nbsp;долгое лечение. Но&nbsp;Саша понимал, что надо бороться, а&nbsp;спортивный опыт ему в&nbsp;этом помог и&nbsp;физически, и&nbsp;психологически. В&nbsp;больнице Саша учился, чтобы не&nbsp;отстать от&nbsp;школьной программы. Благодаря хорошей компании волонтеров и&nbsp;игровой приставке дни в&nbsp;больнице были светлее. Еще Сашу очень поддерживали тренеры и&nbsp;ребята из&nbsp;его хоккейной команды. А&nbsp;лекарства для Саши были приобретены благодаря акции &laquo;Подарок&nbsp;от: важных людей&raquo;. Тогда сотрудники компаний направили сувенирный бюджет в&nbsp;пользу подопечных фонда. Так и&nbsp;сложилась история Сашиного выздоровления.',
  },
  {
    id: 2,
    name: 'Это Лиза',
    name_game: 'Лизы',
    image: lisaIcon,
    description:
      'Нередко лечиться приходится долго. Очень долго. Впервые Лиза попала в&nbsp;больницу в&nbsp;9&nbsp;лет, и&nbsp;еще 8&nbsp;лет ее&nbsp;не&nbsp;отпускал лимфобластный лейкоз. Таблеток, химеотерапии и&nbsp;переливания крови оказалось недостаточно, но&nbsp;Лиза справилась с&nbsp;недугом. В&nbsp;игре вы&nbsp;узнаете, из&nbsp;чего сложилась счастливая история Лизы',
    hints: [
      {
        id: 0,
        name: 'Автомобиль',
        nameEn: 'car',
        image: carIcon,
        description:
          'Благодаря автоволонтеру фонда и&nbsp;его авто Лиза могла посещать концерты и&nbsp;экскурсии',
      },
      {
        id: 1,
        name: 'Старший брат',
        nameEn: 'bigBrother',
        image: bigBrotherIcon,
        description:
          'Именно донорство старшего брата в&nbsp;итоге заставило лейкоз окончательно отступить',
      },
      {
        id: 2,
        name: 'Инвалидная коляска',
        nameEn: 'wheelchair',
        image: wheelchairIcon,
        description:
          'Инвалидная коляска помогала Лизе оставаться мобильной во&nbsp;время лечения',
      },
      {
        id: 3,
        name: 'Донор',
        nameEn: 'donor',
        image: donorIcon,
        description:
          'Донорская кровь не&nbsp;берется в&nbsp;больницах из&nbsp;ниоткуда, это заслуга скромных героев',
      },
      {
        id: 4,
        name: 'Покупки, которые помогают',
        nameEn: 'charitySales',
        image: charitySalesIcon,
        description:
          'Благотворительность может стать частью обычной жизни, например, отправляясь за&nbsp;покупками, можно выбирать товары, которые участвуют в&nbsp;благотворительных акциях, и&nbsp;помогать подопечным фонда',
      },
    ],
    victory:
      'Подопечные не&nbsp;опускают руки даже после нескольких рецидивов, ведь речь идет о&nbsp;жизни. Лиза не&nbsp;сдавалась и&nbsp;победила, пережив больницы, годы лечения, сложные и&nbsp;болезненные процедуры. Ей&nbsp;пришлось выпасть из&nbsp;привычной жизни, но&nbsp;она не&nbsp;утратила интереса к&nbsp;ней. Тем более, что ей&nbsp;помогали волонтеры фонда, с&nbsp;которыми она крепко подружилась. В&nbsp;итоге сложилось все&nbsp;&mdash; и&nbsp;донорство брата, и&nbsp;помощь фонда, и, разумеется, воля Лизы. Сейчас Лизе 21&nbsp;год. Она так впечатлилась работой врачей и&nbsp;фельдшеров, что решила стать медиком и&nbsp;поступила в&nbsp;медицинский вуз.',
  },
];
export default heroList;
