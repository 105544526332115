import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import './WardProfile.scss';
import wardList from '@/data/wards.data';

const WardProfile = ({ className }) => {
  const params = useParams();
  const ward = wardList.find((ward) => ward.id === parseInt(params.id));
  return (
    <div className={classnames('WardProfile', className)}>
      <p
        className='WardProfile__description'
        dangerouslySetInnerHTML={{ __html: ward.description }}
      />
      <div className='WardProfile__wrapper'>
        <img
          className='WardProfile__photo'
          src={ward.photo_after}
          alt='ward photo'
        />
        <p className='WardProfile__name'> {ward.name}</p>
      </div>
    </div>
  );
};

export default WardProfile;
