import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import './Home.scss';
import { Quadrant, LeftPanel, Logo, QuadrantMobile } from '@/components';
import {
  logoBasius,
  logoEmid,
  logoBasiusMobile,
  logoEmidMobile,
} from '@/assets/icons';

const Home = ({ className }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 431px)' });
  const isLandscape = useMediaQuery({ query: '(max-height: 450px)' });
  return (
    <div
      className={classnames(
        'Home',
        {
          'Home--landscape': isLandscape,
        },
        className
      )}
    >
      <Logo className='Home__logo' />
      {!isMobile && (
        <>
          <div className='Home__inner'>
            {isLandscape ? <LeftPanel /> : <LeftPanel showPerson />}
            <Quadrant className='Home__Quadrant' />
          </div>
          <div className='Home__footer'>
            <div className='Home__footer-team'>
              <a
                href='https://basius.tech/'
                className='Home__footer-link'
                target='_blank'
              >
                <img src={logoBasius} alt='logo basius' />
              </a>
              <p className='Home__footer-text'>
                придумали концепцию и всё задизайнили
              </p>
            </div>
            <div className='Home__footer-team'>
              <a
                href='https://emid.agency/'
                className='Home__footer-link'
                target='_blank'
              >
                <img src={logoEmid} alt='logo emid' />
              </a>
              <p className='Home__footer-text'>
                сделали
                <span>этот лендинг</span>
              </p>
            </div>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <QuadrantMobile />
          <LeftPanel className='Home__LeftPanel' />
          <div className='Home__footer'>
            <div className='Home__footer-team'>
              <a
                href='https://basius.tech/'
                className='Home__footer-link'
                target='_blank'
              >
                <img src={logoBasiusMobile} alt='logo basius' />
              </a>
              <p className='Home__footer-text'>
                придумали <span>концепцию</span> и всё задизайнили
              </p>
            </div>
            <div className='Home__footer-team'>
              <a
                href='https://emid.agency/'
                className='Home__footer-link'
                target='_blank'
              >
                <img src={logoEmidMobile} alt='logo emid' />
              </a>
              <p className='Home__footer-text'>
                сделали
                <span>этот лендинг</span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Home;
