import classnames from 'classnames';
import './ZoomButton.scss';
const ZoomButton = ({ className, onClick, zoom }) => {
  return (
    <button className={classnames('ZoomButton', className)} onClick={onClick}>
      {zoom}
    </button>
  );
};

export default ZoomButton;
