import { TelegramShareButton, VKShareButton } from 'react-share';
import classnames from 'classnames';
import './Share.scss';

import { vkIcon, telegramIcon } from '@/assets/icons';

const Share = ({ className }) => {
  const shareUrl = 'https://details.podari-zhizn.ru/';
  const title =
    '«Помогайте. И всё сложится». Новый проект фонда «Подари жизнь» об историях выздоровления, которые стали возможными благодаря очень важной поддержке';
  return (
    <div className={classnames('Share', className)}>
      <p className='Share__text'>
        Своим результатом можно поделиться — это поможет сложить еще больше
        счастливых историй
      </p>
      <div className='Share__buttons'>
        <TelegramShareButton
          className='Share__button'
          url={shareUrl}
          title={title}
        >
          <img className='Share__icon' src={telegramIcon} alt='telegram icon' />
        </TelegramShareButton>
        <VKShareButton
          className='Share__button'
          url={shareUrl}
          title='«Помогайте. И всё сложится». Новый проект фонда «Подари жизнь» об историях выздоровления'
        >
          <img className='Share__icon' src={vkIcon} alt='telegram icon' />
        </VKShareButton>
      </div>
    </div>
  );
};

export default Share;
