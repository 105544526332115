import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './WardCard.scss';

const WardCard = ({ className, link, name, photoBefore, photoAfter }) => {
  return (
    <Link className={classnames('WardCard', className)} to={link}>
      <div className='WardCard__photos'>
        <img
          className='WardCard__photo-before'
          src={photoBefore}
          alt='photo before'
        />
        <img
          className='WardCard__photo-after'
          src={photoAfter}
          alt='photo after'
        />
      </div>
      <p className='WardCard__name'>{name}</p>
    </Link>
  );
};

export default WardCard;
