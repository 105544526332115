import classnames from 'classnames';
import './LeftPanel.scss';
import { Button, Person } from '@/components';
const LeftPanel = ({ showPerson, className }) => {
  return (
    <div className={classnames('LeftPanel', className)}>
      <h1 className='LeftPanel__title'>
        Помогайте.
        <span>И всё сложится</span>
      </h1>
      <Button className='LeftPanel__button' link='/game'>
        Играть
      </Button>
      {showPerson && <Person className='LeftPanel__person' />}
    </div>
  );
};

export default LeftPanel;
