import { Outlet, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import './WardsLayout.scss';

import { LeftPanel, Arrow, Logo } from '@/components';

const WardsLayout = ({ className }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 431px)' });
  return (
    <div className={classnames('WardsLayout', className)}>
      <Logo className='WardsLayout__logo' />
      <LeftPanel className='WardsLayout__LeftPanel' />
      {!isMobile && (
        <div className='WardsLayout__content'>
          <div className='WardsLayout__top'>
            <h1 className='WardsLayout__title'>О подопечных</h1>
          </div>
          <div className='WardsLayout__bottom'>
            <Outlet />
            <Link className='WardsLayout__link' to='..' relative='path'>
              <Arrow />
            </Link>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='WardsLayout__content'>
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default WardsLayout;
