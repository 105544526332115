import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import './Wards.scss';
import { WardCard } from '@/components';
import wardList from '@/data/wards.data';

const Wards = ({ className }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 431px)' });
  return (
    <div className={classnames('Wards', className)}>
      {!isMobile &&
        wardList.map((ward) => {
          return (
            <WardCard
              key={ward.id}
              link={`/wards/${ward.id}`}
              name={ward.name}
              photoAfter={ward.photo_after}
              photoBefore={ward.photo_before}
            />
          );
        })}
      {isMobile && (
        <Swiper
          className='Wards__swiper'
          modules={[Autoplay, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          centeredSlides
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            el: '.Wards__swiper-bullets',
            type: 'bullets',
            bulletClass: 'Wards__swiper-bullet',
            bulletActiveClass: 'Wards__swiper-bullet--active',
          }}
        >
          {wardList.map((ward) => {
            return (
              <SwiperSlide className='Wards__swiper-slide' key={ward.id}>
                <WardCard
                  link={`/wards/${ward.id}`}
                  name={ward.name}
                  photoAfter={ward.photo_after}
                  photoBefore={ward.photo_before}
                />
              </SwiperSlide>
            );
          })}
          <div className='Wards__swiper-bullets'></div>
        </Swiper>
      )}
    </div>
  );
};

export default Wards;
